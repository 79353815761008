<template>
  <div class="main-container">
    <NotFoundView v-if="userNotFound" class="not-found-view" />
    <UserInfoHeader v-if="user && !userNotFound" :user="user" class="user-info-header" />
    <WishView v-for="wish in wishes" :key="wish.id" :title="wish.title" :description="wish.description"
      :donationComment="wish.donation_comment"
      :imageUrl="wish.images && wish.images.length > 0 ? getWishImageUrl(wish.id, wish.images[0].id) : null"
      :isTransparent="wish.images && wish.images[0]?.is_transparent" :similarSatisfies="wish.similar_satisfies"
      :storeUrls="wish.store_urls" @store-click="openStoreLink" />
    <EmptyStatePromoView v-if="!loading && wishes.length === 0 && !userNotFound"
      :title="$t('wishlist.emptyWishlistTitle')" class="empty-view" />
    <PromoSnackButton v-if="!loading && !userNotFound" :title="$t('main.promoSnackTitle')" icon="logo.png"
      @click="handlePromoSnackClick" />
  </div>
</template>

<script>
import axios from 'axios';
import UserInfoHeader from './UserInfoHeader.vue';
import PromoSnackButton from './PromoSnackButton.vue';
import EmptyStatePromoView from './EmptyStatePromoView.vue';
import WishView from './WishView.vue';
import NotFoundView from './NotFoundView.vue';
import { analytics, logEvent } from '../main';
import { useHead } from '@vueuse/head';

export default {
  name: 'UserInfo',
  components: {
    UserInfoHeader,
    PromoSnackButton,
    EmptyStatePromoView,
    WishView,
    NotFoundView,
  },
  data() {
    return {
      user: null,
      wishes: [],
      loading: true,
      userNotFound: false,
    };
  },
  mounted() {
    const shortId = this.$route.params.short_id;
    this.getUserInfo(shortId);
  },
  setup() {
    useHead({
      title: 'Wisher Pro',
      meta: [
        { property: 'og:title', content: 'Wisher Pro' },
        { property: 'og:description', content: 'Personal Wishlist' },
        { property: 'og:image', content: 'https://wisher.pro/favicon.ico' }
      ],
    });
  },
  methods: {
    getUserInfo(shortId) {
      const apiUrl = `${process.env.VUE_APP_API_BASE_URL}/rest/users/short_id/${shortId}`;
      axios.get(apiUrl)
        .then(response => {
          this.user = response.data;
          this.userNotFound = false;
          this.getWishes(this.user.id);
        })
        .catch(error => {
          console.error(error);
          this.userNotFound = true;
          this.loading = false;
        });
    },
    getWishes(userId) {
      const apiUrl = `${process.env.VUE_APP_API_BASE_URL}/rest/users/${userId}/wishes`;
      axios.get(apiUrl)
        .then(response => {
          this.wishes = response.data;
        })
        .catch(error => {
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getWishImageUrl(wishId, imageId) {
      return `${process.env.VUE_APP_API_BASE_URL}/rest/wishes/${wishId}/images/${imageId}?compressed=true`;
    },
    handlePromoSnackClick() {
      logEvent(analytics, 'promo_snack_click');
      window.location.href = 'https://wisher.pro';
    },
    openStoreLink(url) {
      window.location.href = url;
    }
  }
};
</script>

<style>
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: auto;
  font-family: 'SF Pro', sans-serif;
}

.main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 8px;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  overflow: visible;
}

.user-info-header {
  margin-top: 60px;
}

.empty-view {
  margin-top: 20px;
  margin-bottom: 80px;
}

.not-found-view {
  margin-top: 0px;
}

@media (max-width: 767px) {
  .not-found-view {
    margin-top: -30px;
  }
}
</style>

<template>
    <div class="wish-container">
        <div class="wish-header">
            <h2 class="wish-title">
                <span>{{ title }}</span>
            </h2>
            <div class="image-container" :class="{ 'with-border': imageLoaded && !isTransparent }">
                <img v-if="imageUrl" :src="imageUrl" :style="imageStyle" :class="{
                    'aspect-fit': isTransparent,
                    'aspect-fill': !isTransparent
                }" alt="Wish Image" class="wish-image" @load="onImageLoad" @error="imageLoaded = false" />
            </div>
        </div>
        <p v-if="description" class="wish-description">{{ description }}</p>
        <template v-if="donationComment">
            <p class="wish-donation-title">{{ $t('wishlist.howToGiveTitle') }}</p>
            <p class="wish-donation-comment">{{ donationComment }}</p>
        </template>
        <div class="tags-container" v-if="showTags">
            <TagView v-if="similarSatisfies" :text="$t('tags.similarSatisfies')" icon="icon.tag.similar.svg"
                class="left-tag" />
            <ClickableTagView v-if="storeUrls && storeUrls.length > 0 && storeUrls[0] !== ''"
                :text="$t('wishlist.storeButtonTitle')" icon="shop.link.icon.svg" class="right-tag"
                @click.stop="handleStoreClick(storeUrls[0])" />
        </div>
    </div>
</template>

<script>
import TagView from "./TagView.vue";
import ClickableTagView from "./ClickableTagView.vue";

export default {
    name: "WishView",
    props: {
        title: { type: String, required: true },
        description: { type: String, default: null },
        donationTitle: { type: String, default: null },
        donationComment: { type: String, default: null },
        imageUrl: { type: String, default: null },
        isTransparent: { type: Boolean, default: null },
        similarSatisfies: { type: Boolean, default: false },
        storeUrls: { type: Array, default: () => [] },
    },
    data() {
        return {
            imageLoaded: false,
            imageStyle: {
                objectFit: "contain",
                objectPosition: "center",
            },
        };
    },
    mounted() {
        this.calculateImageStyle();
    },
    watch: {
        imageUrl: "calculateImageStyle",
    },
    computed: {
        showTags() {
            return this.similarSatisfies || (this.storeUrls && this.storeUrls.length > 0);
        },
        imageObjectFit() {
            return this.isTransparent ? 'contain' : 'cover';
        },
    },
    methods: {
        onImageLoad(event) {
            this.imageLoaded = true;
            const img = event.target;
            const isWide = img.naturalWidth > img.naturalHeight;

            this.imageStyle = {
                objectFit: "cover",
                objectPosition: isWide ? "center top" : "left center",
            };
        },
        calculateImageStyle() {
            if (!this.imageUrl || this.isTransparent) {
                return;
            }
            const img = new Image();
            img.onload = () => {
                const isWide = img.width > img.height;
                this.imageStyle = {
                    "object-fit": "cover",
                    "object-position": isWide ? "center top" : "left center",
                };
            };
            img.src = this.imageUrl;
        },
        handleStoreClick(storeUrl) {
            this.$emit("store-click", storeUrl);
        },
    },
    components: {
        TagView,
        ClickableTagView,
    },
};
</script>

<style>
.wish-container {
    background-color: #fff;
    border-radius: 24px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    padding: 16px;
    margin: 16px 0;
    width: calc(100% - 40px);
    max-width: 400px;
    text-align: left;
    position: relative;
}

@media (max-width: 767px) {
    .wish-container {
        width: calc(100% - 60px);
    }

    .wish-title {
        max-width: 70%;
    }
}

@media (min-width: 768px) {
    .wish-container {
        width: 400px;
    }

    .wish-title {
        max-width: 300px;
    }
}

/* .wish-title {
    font-size: 24px;
    font-weight: 600;
    margin: 5px 0 0 5px;
    background-color: #EEF2F7;
    padding: 0px 0px;
    display: inline;
    color: #1B202A;
    width: auto;
} */

.wish-title {
    font-size: 24px;
    font-weight: 600;
    margin: 5px 0 0 5px;
    color: #1B202A;
    width: auto; /* Keep the title width flexible */
}

.wish-title span {
    background-color: #EEF2F7;
    padding: 0 4px;
    border-radius: 4px;
    display: inline;
    line-height: 1.4;
}

.wish-container:last-child {
    margin-bottom: 100px;
}

.tags-container {
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
}

.wish-donation-title {
    font-size: 14px;
    font-weight: 500;
    margin: 11px 5px 0 5px;
    color: #6D441C;
    background-color: #FEFCC6;
    padding: 0px 2px;
    display: inline-block;
}

.wish-description {
    font-size: 15px;
    font-weight: 500;
    margin: 11px 5px 0 5px;
    line-height: 1.3;
    color: #1B202A;
}

.wish-donation-comment {
    font-size: 15px;
    font-weight: 400;
    margin: 11px 5px 5px 5px;
    line-height: 1.3;
    color: #1B202A;
}

.tags-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;
}

.left-tag {
    margin-right: auto;
}

.right-tag {
    margin-left: auto;
}

.with-border {
    border: 3px solid #EEF2F7;
}

.aspect-fit {
    object-fit: contain;
}

.aspect-fill {
    object-fit: cover;
}

.wish-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.image-container {
    border-radius: 20px;
    width: 75px;
    height: 75px;
    display: flex;
    justify-content: center;
    margin-top: -30px;
    align-items: center;
}

.image-container.with-border {
    border: 3px solid #EEF2F7;
}

.wish-image {
    width: 100%;
    height: 100%;
    border-radius: 17px;
}
</style>
<template>
  <div class="not-found-view">
    <h1 class="large-title">404</h1>
    <h2 class="title">{{ $t('notFound.title') }}</h2>
    <p class="subtitle">{{ $t('notFound.subtitle') }}</p>
  </div>
</template>

<script>
export default {
  name: "NotFoundView"
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@400&display=swap');

.not-found-view {
  text-align: center;
  padding: 10px;
  max-width: 90%;

}

.large-title {
  font-size: 160px;
  margin-bottom: 10px;
  text-decoration: none;
  font-family: 'Source Code Pro', monospace;
}

.title {
  font-size: 50px;
  margin-bottom: 5px;
}

.subtitle {
  font-size: 20px;
  color: #555;
}
</style>
import { createRouter, createWebHistory } from 'vue-router';
import MainPage from '../components/MainPage.vue';
import UserInfo from '../components/UserInfo.vue';
import About from '../components/AboutPage.vue';
import Contact from '../components/ContactPage.vue';
import PrivacyPolicy from '../components/PrivacyPage.vue';
import UserAgreement from '../components/AgreementPage.vue';
import { analytics, logEvent, i18n } from '@/main';
import en from '../locales/en.json';
import ru from '../locales/ru.json';
import kk from '../locales/kk.json';
import uk from '../locales/uk.json';

const messages = {
    en,
    ru,
    kk,
    uk
};

// Список поддерживаемых локалей
const supportedLocales = ['en', 'ru', 'kk', 'uk'];
const idExceptions = ['about', 'terms'];


// Определяем начальную локаль на основе языка браузера
const getBrowserLocale = () => {
    const browserLang = navigator.language.split('-')[0];
    return messages[browserLang] ? browserLang : 'en';
};

const isShortId = (checkString) => {
    const isValidFormat = /^[A-Za-z0-9_]{5}$/.test(checkString);

    // Проверяем, что строка не входит в массив исключений
    const isNotInExceptions = !idExceptions.includes(checkString);
   if (isValidFormat && isNotInExceptions) console.log('это short id')
    // Возвращаем результат
    return isValidFormat && isNotInExceptions;
};

const routes = [
    {
        path: '/:short_id',
        name: 'Wishlist',
        component: UserInfo,
        meta: {
            title: 'Wisher Pro'
        },
        beforeEnter: (to, from, next) => {
            if (isShortId(to.params.short_id)) {
                const locale = getBrowserLocale();
                i18n.global.locale.value = locale;
                next();
            } else {
                const locale = getBrowserLocale();
                i18n.global.locale.value = locale;
                supportedLocales.includes(to.params.short_id) ? next({name: 'Welcome', params: {locale: to.params.short_id}}) : next(`/${locale}${to.path}`);
                
            }
        }
    },
    {
        path: '/:locale',
        name: 'Welcome',
        component: MainPage,
        meta: {
            title: 'Wisher Pro',
            hreflang: {
                en: 'https://wisher.pro/en',
                ru: 'https://wisher.pro/ru',
                uk: 'https://wisher.pro/uk',
                kk: 'https://wisher.pro/kk',
                default: 'https://wisher.pro/en',
            },
        }
    },
    {
        path: '/:locale/about',
        name: 'AboutPage',
        component: About,
        meta: {
            title: 'Wisher Pro',
            hreflang: {
                en: 'https://wisher.pro/en/about',
                ru: 'https://wisher.pro/ru/about',
                uk: 'https://wisher.pro/uk/about',
                kk: 'https://wisher.pro/kk/about',
                default: 'https://wisher.pro/en/about',
            },
        }
    },
    {
        path: '/:locale/contact',
        name: 'ContactPage',
        component: Contact,
        meta: {
            title: 'Wisher Pro',
            hreflang: {
                en: 'https://wisher.pro/en/contact',
                ru: 'https://wisher.pro/ru/contact',
                uk: 'https://wisher.pro/uk/contact',
                kk: 'https://wisher.pro/kk/contact',
                default: 'https://wisher.pro/en/contact',
            },
        }
    },
    {
        path: '/:locale/privacy',
        name: 'PrivacyPage',
        component: PrivacyPolicy,
        meta: {
            title: 'Wisher Pro',
            hreflang: {
                en: 'https://wisher.pro/en/privacy',
                ru: 'https://wisher.pro/ru/privacy',
                uk: 'https://wisher.pro/uk/privacy',
                kk: 'https://wisher.pro/kk/privacy',
                default: 'https://wisher.pro/en/privacy',
            },
        }
    },
    {
        path: '/:locale/terms',
        name: 'AgreementPage',
        component: UserAgreement,
        meta: {
            title: 'Wisher Pro',
            hreflang: {
                en: 'https://wisher.pro/en/terms',
                ru: 'https://wisher.pro/ru/terms',
                uk: 'https://wisher.pro/uk/terms',
                kk: 'https://wisher.pro/kk/terms',
                default: 'https://wisher.pro/en/terms',
            },
        }
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});


router.beforeEach((to, from, next) => {

    // Если это роут Wishlist, пропускаем проверку локали
    if (to.name === 'Wishlist') {
        document.title = to.meta.title || 'Default Title';
        return next();
    }

    // Проверяем, есть ли локаль в маршруте
    const hasLocaleInPath = !!to.path.split('/')[1] && supportedLocales.includes(to.path.split('/')[1]);
    console.log('hasLocaleInPath:', hasLocaleInPath);
    if (!hasLocaleInPath) {
        const locale = getBrowserLocale();
        console.log('No locale in route, redirecting to:', locale);

        // Если это корневой путь
        if (to.path === '/') {
            return next({name: 'Welcome', params: {locale: locale}});
        }

        // Для остальных путей добавляем локаль в начало
        return next(`/${locale}${to.path}`);
    }

    // Если локаль есть, проверяем её валидность
    const locale = to.params.locale;
    if (!supportedLocales.includes(locale)) {
        // Если локаль не поддерживается, редиректим на английскую версию
        return next(`/en${to.path.substring(to.params.locale.length + 1)}`);
    }

    // Устанавливаем локаль для i18n
    i18n.global.locale.value = locale;

    document.title = to.meta.title || 'Default Title';

    next();
});

router.afterEach((to) => {
    logEvent(analytics, 'page_view', {
        page_name: to.name
    });
});

export default router;

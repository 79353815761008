<template>
  <link rel="icon" href="/favicon.ico">
  <div class="agreement-page">
    <div v-if="error">
      <p>Error loading Agreement. Please try again later.</p>
    </div>
    <div v-else>
      <div v-for="(section, index) in sections" :key="index" class="agreement-item">
        <h2 class="agreement-title">{{ section.title }}</h2>
        <p class="agreement-text" v-html="section.text"></p>
      </div>
    </div>
  </div>
</template>

<script>
import { useHead } from '@vueuse/head';
import { useRoute } from 'vue-router';
import { useI18n } from "vue-i18n";
import agreementData from '@/assets/docs/terms/en.json';

export default {
  name: 'AgreementPage',
  data() {
    return {
      sections: [],
      error: false,
    };
  },
  mounted() {
    this.fetchAgreementSections();
  },
  methods: {
    fetchAgreementSections() {
      try {
        this.sections = agreementData;
        this.error = false;
      } catch (error) {
        console.error('Error fetching Agreement:', error);
        this.error = true;
        this.sections = [];
      }
    },
  },
  setup() {
    const { t } = useI18n();
    const domain = window.location.hostname;
    const commonHeadConfig = {
      link: [
        { rel: 'icon', href: '/favicon.ico' },
      ],
      title: t('meta.title'),
      meta: [
        { name: 'description', content: t('meta.description') },
        { property: 'og:title', content: t('meta.ogTitle') },
        { property: 'og:description', content: t('meta.ogDescription') },
        { property: 'og:url', content: window.location.href },
        { property: 'og:type', content: 'website' },
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'application-name', content: 'Wisher Pro' },
        { property: 'og:image', content: '/favicon.ico' }
      ],
    };
    if (domain === 'wisher-test.pro') {
      useHead({
        ...commonHeadConfig,
        meta: [
          ...commonHeadConfig.meta,
          { name: 'robots', content: 'noindex, nofollow' },
        ],
      });
    } else if (domain === 'wisher.pro') {

      const route = useRoute();
      const hreflang = route.meta.hreflang;
      const links = hreflang
        ? Object.entries(hreflang).map(([lang, href]) => ({
          rel: 'alternate',
          hreflang: lang === 'default' ? 'x-default' : lang,
          href,
        })) : [];

      useHead({
        ...commonHeadConfig,
        title: t('meta.title'),
        meta: [
          ...commonHeadConfig.meta,
          { name: 'description', content: t('meta.description') },
          { property: 'og:title', content: t('meta.ogTitle') },
          { property: 'og:description', content: t('meta.ogDescription') },
          { property: 'og:url', content: 'https://wisher.pro' },
          { property: 'og:image', content: 'https://wisher.pro/favicon.ico' }
        ],
        link: [
          ...(commonHeadConfig.link || []),
          ...links,
        ],
      });
    } else {
      useHead(commonHeadConfig);
    }
  },
};
</script>

<style scoped>
.agreement-page {
  padding: 20px;
  max-width: 100%;
  margin: 0 auto;
}

.agreement-item {
  margin-bottom: 10px;
}

.agreement-title {
  font-size: 20px;
  display: inline-block;
  margin-bottom: 4px;
}

.agreement-text {
  font-size: 18px;
}

@media (min-width: 1024px) {
  .agreement-page {
    max-width: 50%;
  }
}
</style>

<template>
  <link rel="icon" href="/favicon.ico">
  <div class="contact-page">
    <div class="content-section">
      <h1>{{ $t('contacts.title') }}</h1>
      <div class="subtitle">{{ $t('contacts.subtitle') }}</div>
      <div class="contact-info">
        <div v-for="(item, index) in contactItems" :key="index" class="contact-item">
          <div class="contact-text">{{ $t(item.title) }}</div>
          <div class="contact-label">{{ $t(item.subtitle) }}</div>
          <div class="contact-link">{{ $t(item.linkText) }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useHead } from '@vueuse/head';
import { useRoute } from 'vue-router';
import { useI18n } from "vue-i18n";

export default {
  name: 'ContactPage',
  data() {
    return {
      contactItems: [
        { title: 'contacts.supportEmailTitle', subtitle: 'contacts.supportEmailSubtitle', linkText: 'support@wisher.pro' },
        { title: 'contacts.prEmailTitle', subtitle: 'contacts.prEmailSubtitle', linkText: 'pr@wisher.pro' },
        { title: 'contacts.defaultEmailTitle', subtitle: 'contacts.defaultEmailSubtitle', linkText: 'info@wisher.pro' },
        { title: 'contacts.companyTitle', subtitle: 'contacts.companyBody', linkText: '' }
      ]
    };
  },
  setup() {
    const { t } = useI18n();
    const domain = window.location.hostname;
    const commonHeadConfig = {
      link: [
        { rel: 'icon', href: '/favicon.ico' },
      ],
      title: t('meta.title'),
      meta: [
        { name: 'description', content: t('meta.description') },
        { property: 'og:title', content: t('meta.ogTitle') },
        { property: 'og:description', content: t('meta.ogDescription') },
        { property: 'og:url', content: window.location.href },
        { property: 'og:type', content: 'website' },
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'application-name', content: 'Wisher Pro' },
        { property: 'og:image', content: '/favicon.ico' }
      ],
    };
    if (domain === 'wisher-test.pro') {
      useHead({
        ...commonHeadConfig,
        meta: [
          ...commonHeadConfig.meta,
          { name: 'robots', content: 'noindex, nofollow' },
        ],
      });
    } else if (domain === 'wisher.pro') {

      const route = useRoute();
      const hreflang = route.meta.hreflang;
      const links = hreflang
        ? Object.entries(hreflang).map(([lang, href]) => ({
          rel: 'alternate',
          hreflang: lang === 'default' ? 'x-default' : lang,
          href,
        })) : [];

      useHead({
        ...commonHeadConfig,
        title: t('meta.title'),
        meta: [
          ...commonHeadConfig.meta,
          { name: 'description', content: t('meta.description') },
          { property: 'og:title', content: t('meta.ogTitle') },
          { property: 'og:description', content: t('meta.ogDescription') },
          { property: 'og:url', content: 'https://wisher.pro' },
          { property: 'og:image', content: 'https://wisher.pro/favicon.ico' }
        ],
        link: [
          ...(commonHeadConfig.link || []),
          ...links,
        ],
      });
    } else {
      useHead(commonHeadConfig);
    }
  },
};
</script>

<style scoped>
.contact-page {
  font-family: Arial, sans-serif;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-height: 100vh;
}

.content-section {
  text-align: left;
  width: 40%;
  max-width: 1200px;
}

h1 {
  font-size: 2em;
  margin-top: 40px;
  margin-bottom: 20px;
}

.subtitle {
  font-size: 17px;
  margin-bottom: 30px;
}

.contact-info {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.contact-item {
  display: flex;
  flex-direction: column;
}

.contact-text {
  margin-top: 14px;
  margin-bottom: 5px;
  font-weight: bold;
}

.contact-label {
  margin-top: 5px;
  margin-bottom: 7px;
  white-space: pre-wrap;
  word-wrap: break-word;
  line-height: 1.7;
}

.contact-link {
  font-size: 15px;
  color: #6D441C;
  background-color: #FEFCC6;
  font-family: 'Source Code Pro', monospace;
  padding: 4px 0px;
  display: inline-block;
  width: max-content;
  box-sizing: border-box;
  border-radius: 4px;
}

@media (max-width: 768px) {
  .contact-page {
    display: block;
    padding: 20px;
    text-align: left;
    align-items: flex-start;
  }

  .content-section {
    text-align: left;
    width: auto;
    max-width: none;
  }

  .contact-info {
    gap: 15px;
  }
}
</style>
